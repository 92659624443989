<template>
<div class="userCarAuth">
    <i-header title="授权人" :showBack="true" @back-event="toBack">
        <div v-if="isDsm" slot="right" @click="navToAppliedList" class="right_action">申请名单</div>
    </i-header>
    <i-list v-if="appliedList.length" v-model="loading" :offset="1" :finished="finished" finished-text="没有更多了"
            :immediate-check="false"
            @load="nextPage">
        <div class="item" v-for="(item,index) in appliedList" :key="index">
            <div class="left">
                <div class="title">
                    <div class="title-name">{{ item.name }}
                    <div class="sub_title">员工号：{{ item.extId }}</div>
                    </div>
                </div>
<!--                <div class="desc">员工号：</div>-->
                <div class="desc" style="margin-top: 0.08rem"> <i-icon size="14" color="#13161B" name="icon-qita" />{{ handleTenantType(item) || '-' }}</div>
                <div class="desc"><i-icon size="14" color="#13161B" name="icon-youxiang"/>{{ item.email || '（暂无）' }}</div>
            </div>
            <div class="right">
                <i-button @click.native="remove(item)">删除</i-button>
            </div>
        </div>
    </i-list>
    <empty-box v-if="!appliedList.length" />
    <i-add-button ref="iAddButton" style="z-index: 3" @click="addNew" />
</div>
</template>

<script>
/**
 *
 * @description 住友和辉瑞用车授权及其关联页面
 */
import emptyBox from "@/components/iEmpty";
import {getCookie} from 'tiny-cookie'
export default {
    components: {
        emptyBox
    },
    name: "index",
    data(){
        return {
            appliedList: [],
            miceId: '',
            loading: false,
            finished: true,
            isDsm: getCookie('tenant') === 'dsm',
            isPfizer: getCookie('tenant') === 'pfizer'
        }
    },
    methods: {
        handleTenantType(item){
            if(this.isPfizer){
                return item.positionName
            }else {
                return item.group6
            }
        },
        remove(item){
            this.$idialog.confirm({
                message: '是否取消授权？',
                title: '提示'
            }).then(() => {
                let params = {
                    agentType: '2',
                    agentId: item.agentId
                }
                this.$service.removeAuthorizer(params).then(res => {
                    if (res.success) {
                        this._getAuthorizationList();
                        this.$toast.success('成功');
                    }
                });
            })
        },
        addNew(){
            this.$router.push({name: 'pickAuthorizer', query: {
                miceId: this.miceId
                }})
        },
        nextPage(){},
        toBack(){
            this.$router.go(-1);
        },
        navToAppliedList() {
            this.$router.push({name: 'appliedList', query: {
                miceId: this.miceId
                }})

        },
        // 获取授权人
        _getAuthorizationList(){
            this.$service.getAuthorizationList({agentType: 2, miceId: this.miceId, rnd: new Date().getTime()}).then(res => {
                if(res.success){
                    this.appliedList = res.content;
                }
            })
        }
    },
    mounted() {
        const {miceId} = this.$route.query;
        // TODO 记得注释回来使用miceId
        // this.miceId = '534aa55e-9f4b-48bb-ad93-fce55be7c5c6';
        this.miceId = miceId;
        this._getAuthorizationList();
    }
}
</script>

<style scoped lang="less">
.userCarAuth{
    .right_action{
        color: #13161B;
    }
    .van-list{
        padding: 0 0.12rem;
        height: calc(100vh - 0.44rem);
        overflow: auto;
        .item{
            border-bottom: 0.01rem solid #F5F5F5;
            padding: 0.14rem 0;
            .left{
                .title{
                    .title-name{
                        font-size: 0.16rem;
                        font-weight: bold;
                        color: #13161B;
                        display: flex;
                        align-items: flex-end;
                        justify-content: flex-start;
                        .sub_title{
                            font-size: 0.12rem;
                            font-weight: lighter;
                            color: #909399;
                            margin-left: 0.1rem;
                        }
                    }
                }
            }
            &:nth-last-child(3){
               border-bottom: none;
            }

            .desc{
                font-size: 0.14rem;
                color: #13161B;
                font-weight: normal;
                display: flex;
                align-items: center;
                justify-content: flex-start;
                svg{
                    margin-right: 0.06rem;
                }
            }
            .right{
                text-align: right;
                .van-button{
                    height: 0.32rem!important;
                    color: #909399;
                }
            }
        }
    }

}
</style>
